@import '../breakpoints.scss';

nav {
    background-color: #EEEEEE;
}

.navbar-brand {
    font-size: 9px!important;

    @include sm {
        font-size: 16px!important;
    }

    @include md {
        font-size:20px!important;
    }
}