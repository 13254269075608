body {
    font-family: 'Montserrat', 'Arial', 'Sans-Serif'!important;
    background-color: #EEEEEE!important;
}

h5 {
    text-align: center;
}

.logo {
    width: 100px;
}

.main_image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
}