@import '../breakpoints.scss';

.posts {
    display: flex;
    flex-wrap: wrap;
    padding: 25px;
}

.posts .post {
    flex-basis: 100%;
    padding: 25px;

    @include md {
        flex-basis: 50%;
    }
}

.post {
    padding: 25px;
    text-align: center;
    margin: 0 auto;
    max-width: 75%;
}

.posts .post img {
    max-height: 250px;
    width: auto;
    border-radius: 10px;
    margin-bottom: 15px;
}

.post h1 {
    text-align: center;
}

.post a {
    text-decoration: none;
    color: #212529;
    text-align: center;
}

.post a:hover {
    color: #606E71;
    cursor: pointer;
}

.post_date {
    text-align: center;
}

.post img {
    max-height: 300px;
    width: auto;
    border-radius: 10px;
    margin-bottom: 15px;

    @include md {
        max-height: 500px
    }
}

.post_body {
    text-align: justify;
}