@import '../../breakpoints.scss';

navbar {
    display: none;
}

.affiliate_link {
    display: none;
}

.login {
    max-width: 75%;
    margin: 50px auto 0  auto;
    border: 1px solid #CDCDCD;
    border-radius: 4px;
    box-shadow: 1px 1px #CDCDCD;
    padding: 15px;

    @include md {
        max-width: 50%;
    }

    button {
        margin: 0 auto;
        display: block;
    }
}