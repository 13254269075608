.contact_us .image_container {
    background-color: black;
    margin-bottom: 25px;
}

.contact_links {
    margin: 0 auto;
    width: 75%;
    text-align: center;
}

.contact_link {
    display: inline-block;
    vertical-align: top;
    padding: 25px;
}

.contact_link a {
    text-decoration: none;
    color: #212529;
}

.contact_link a:hover {
    color: #606E71;
}

.contact_links img {
    width: 100px;
}