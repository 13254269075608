@import '../breakpoints.scss';

.comments {
    max-width: 75%;
    margin: 0 auto;
}

.comments .comment {
    padding-bottom: 10px;
}

.comment_form {
    max-width: 75%;
    margin: 0 auto;

    button {
        margin: 0 auto;
        display: block;
    }
}

.comment_approval {
    display: none;
}