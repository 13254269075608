@import '../breakpoints.scss';

.affiliate_link {
    text-align: center;
}

.affiliate_link img {
    max-width: 300px;
    margin-top: 15px;

    @include md {
        max-width: 468px;
    }
}