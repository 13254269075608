@import '../breakpoints.scss';

.images-grid-container {
    margin: 1em auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
}

.images-col {
    flex: 100%;
 
    @media only screen and (min-width: 31.375em) {  // 502px
        flex: 50%;
    }

    @media only screen and (min-width: 48em) {  // 768px 
        flex: 50%;
    }
}

.grid-item {
    padding: 1rem;
    text-align: center;
 
    & img {
        width: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.8);

        @media only screen and (min-width: 750px) {
            height: 28.125em;
        }
    }
}

.image {
    text-align: center;
    
    img {
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.8); 

        @media only screen and (min-width: 601px) {
            max-height: 625px;
         width: auto;
        }

        @media only screen and (max-width: 600px) {
            width: 100%;
        height: auto;
        }
    }

    h1 {
        text-align: center;
    }
}

.image-link {
    text-decoration: none;
    color: black;
}

.image-title {
    margin-top: 10px;
    margin-bottom: 1px;
}

.image-date {
    margin-top: 1px;
}

.grow { 
    transition: all .2s ease-in-out; 
}
    
.grow:hover { 
    transform: scale(1.05); 
}

.modal, .modal-dialog {
    max-width: none !important;
    width: 85%!important;

    @media only screen and (max-width: 750px) {
        width: 95%!important;
    }
}

.modal-header {
    background-color: #212529!important;
    color: #EEEEEE;

    .btn-close {
        background-color: #EEEEEE!important;
    }
}

.modal-body {
    text-align: center!important;
    background-color: #212529!important;

    @media only screen and (max-width: 750px) {
        width: 100%!important;
        padding: 0!important;
    }
}

#modal-image {
    margin: 0 auto;
    width: 90%;

    @media only screen and (max-width: 750px) {
        width: 100%;
    }
}
